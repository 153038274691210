import axios from '@/plugins/axios';

class ActivationOfDeviceApi {
  static getDevices(
    page = 1,
    limit = 10,
    channelId = NaN,
    storeId = NaN,
    startDate = '',
    endDate = '',
  ) {
    return axios({
      url: '/api/stat/product/active/page',
      data: {
        page,
        limit,
        channel_id: channelId,
        store_id: storeId,
        start_date: startDate,
        end_date: endDate,
      },
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询激活设备列表',
        },
      },
    });
  }

  static export(data) {
    return axios({
      url: '/api/stat/product/active/export',
      method: 'post',
      data,
      headers: {
        meta: {
          skipSuccess: true,
          desc: '导出',
        },
      },
    });
  }
}

export default ActivationOfDeviceApi;
